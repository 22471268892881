<template>
  <!-- 画板组件容器 （配置工具栏） -->
  <div class="shape">
    <!-- 组件工具栏 -->
    <div
      v-if="data.component != 'waiting'"
      class="shape-tab"
      @click="setcurComponent(data)"
    >
      <!-- 删除 -->
      <template v-if="control.curWidget && control.curWidget.id == data.id">
        <i
          class="icon icon-shanchu tab-icon f16"
          @click.stop="delComponent(data.id)"
        ></i>
      </template>

      <!-- 组件名 -->
      <span v-else>{{ data.name }}</span>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "shape",

  inject: ["control"],

  props: {
    data: {
      type: Object,
    },
    isBuyer: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    // 修改选中物料
    setcurComponent() {
      this.$emit("changeCurrWidget", this.data);
    },

    // 删除物料
    delComponent(id) {
      let index = this.getMyDataHandle().findIndex((item) => item.id == id);
      let curPageId = this.control.curPage.id;
      if (curPageId == "my") {
        // 买家
        if (!this.isBuyer) {
          this.control.curPage.componentListBuyer.splice(index, 1);
        } else {
          // 卖家
          this.control.curPage.componentListSeller.splice(index, 1);
        }
      } else {
        this.control.curPage.componentList.splice(index, 1);
      }
      this.control.curWidget = undefined;
    },
    // 区分我的页面买家和卖家数据
    getMyDataHandle() {
      let id = this.control.curPage.id;
      let componentList = [];
      // 我的页面分为买家 和 卖家
      if (id == "my") {
        // 买家
        if (!this.isBuyer) {
          componentList = this.control.curPage.componentListBuyer;
        } else {
          // 卖家
          componentList = this.control.curPage.componentListSeller;
        }
      } else {
        componentList = this.control.curPage.componentList;
      }
      console.log(componentList);

      return componentList;
    },
  },
};
</script>

<style lang="scss" scoped>
.shape {
  position: relative;

  .shape-tab {
    position: absolute;
    right: -86px; /*no*/
    top: 0; /*no*/
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; /*no*/
    height: 24px; /*no*/
    font-size: 12px; /*no*/
    color: #333;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      right: 100%; /*no*/
      top: 7px; /*no*/
      width: 0; /*no*/
      height: 0; /*no*/
      border-width: 5px; /*no*/
      border-style: solid;
      border-color: transparent;
      margin-bottom: -1px; /*no*/
      border-right-width: 5px; /*no*/
      border-right-color: currentColor;
      color: #fff;
    }
  }

  .event-none {
    pointer-events: none;
  }

  .tab-icon {
    color: #969799;
    cursor: pointer;

    &:hover {
      color: #87888a;
    }
  }
}
</style>
